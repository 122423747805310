body {
  cursor: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.brand-font {
  font-family: "Sacramento", serif;
  font-weight: 400;
  font-style: normal;
  color: #FFB300;
}

.brand-font .primary {
  color: #FFB300;
}

.brand-font .white {
  color: #FFFFFF;
}

.brand-font .secondary {
  color: #29B6F6;
}

/* Define a CSS rule to apply the same font-family to all text tags */
p, h3, h4, h5, h6, strong, em, u, small, mark, del, ins, sub, sup, blockquote, pre, code {
  font-family: "Geist Mono", monospace!important;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

/* .blue-gradient-blue-gradient { 
  margin: 0; height: 100%; display: flex; 
  justify-content: center; align-items: center; 
  background: linear-gradient(135deg, #1565C0, #81D4FA); 
  background-size: 200% 200%; animation: gradient 10s ease infinite; } 
  @keyframes gradient { 
    0% { background-position: 0% 50%; } 
    50% { background-position: 100% 50%; } 
    100% { background-position: 0% 50%; }
  } */

  .blue-gradient-blue-gradient {
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #1565C0, #B3E5FC, #03A9F4);
    background-size: 300% 300%;
    animation: gradient 10s ease infinite;
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

 /* Recover Cards */
 .card {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  cursor: pointer;
  max-height: 80px; /* Default height */
}

.card.expanded {
  max-height: 100%; /* Adjust based on content */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.card:not(.expanded) {
  max-height: 110px!important;
}

.card:hover {
  transform: scale(1.02);
}

@keyframes wave {
  0% {
      transform: translateX(0) rotate(0deg);
  }
  50% {
      transform: translateX(30px) rotate(5deg); /* Move to the right and rotate slightly */
  }
  100% {
      transform: translateX(0) rotate(0deg); /* Move back to the original position */
  }
}

