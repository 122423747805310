.fireball-cursor, .fireball-trail {
  position: fixed;
  width: 12px;
  height: 12px;
  background: radial-gradient(circle, orange, #FBC02D);
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 15px rgba(255, 111, 0, 0.5), 0 0 30px rgba(255, 111, 0, 0.5);
  z-index: 9999;
  transition: transform 0.1s ease-out; /* Apply smooth transition for cursor movement */
}

.fireball-trail {
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out; /* Smooth fade effect for the trail */
}

/* Optional: Adding a subtle scale effect for more dynamic feel */
.fireball-cursor {
  transition: transform 0.1s ease-out, transform 0.15s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth movement with some easing */
}

/* Optional: Adding a subtle scaling for the cursor itself */
.fireball-cursor:active {
  transform: translate(-50%, -50%) scale(1.2);
}
